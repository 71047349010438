import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import './index.css'
import Header from './Header';


const useStyles = makeStyles({
    root: {
        height: '100%',
        overflowY: 'hidden',
        position: 'relative',
    },
    blog_body: {
        overflowY: 'auto',
        height: 'calc(100vh - 90px)',
        marginTop: '70px',
        width: '100%',
    },
})

const Layout: React.FC = ({children}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Header/>
            <div className={classes.blog_body}>
                {children}
            </div>
        </div>
    )
}

export default Layout