import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Layout from '../components/layout';
import SEO from '../components/seo';


const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		marginTop: '2rem',
		'& > h1': {
		    fontSize: '4rem',
		    fontWeight: 500,
		    margin: '1rem 0rem',
		},
		'& > h2': {
		    fontSize: '2rem',
		    margin: '0rem 0rem 0.5rem',
		    fontWeight: 400,
		},
		'& > p': {
		    textAlign: 'center',
		}
	},
});

const NotFoundPage: React.FC<{}> = () => {
	const styles = useStyles();
	const siteLink = (): string => {
	    return process.env.GATSBY_SQUAREDEAL_APP_URL || ''
	}
	return (
	    <Layout>
		    <div className={styles.root}>
		        <SEO title="404: Not found" />
			    <img
			        src="https://res.cloudinary.com/agiliti/image/upload/v1639380140/error.svg"
			        alt="error"
			        title="sorry, there was a problem"
			    />
			    <h1>404</h1>
			    <h2>PAGE NOT FOUND</h2>
			    <p>
			    	The page you are looking for does not exist or it is no longer available.<br />
                    Go back, or head over for <a href={siteLink()}>Square Deal</a> to redirect the page.
			    </p>
		    </div>
		</Layout>
	);
};

export default NotFoundPage;